import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Security from "../modules/SubFetaures/security.js"

const security = () => (
  <Layout>
    <SEO title="Security and Access" 
    description="Deployed on AWS cloud, Korero has number of features to ensure safety of your data. 
    Built for omni-channel access 24/7, Korero is laptop and tablet friendly, mobile app to be released shortly."/>

    <Security />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default security
