import React from "react"

import security2 from "../../images/security1.jpg"

import mobile from "../../images/Mobile.jpg"
import feature6 from "../../images/Feature6.jpg"
import Signup from "../Home/signup"
import Dashboard_highres from "../../images/Dashboard_highres.jpg"
import Contact_Mobile_highres from "../../images/Contact_Mobile_highres.jpg"
import Event_Add_highres from "../../images/Event_Add_highres.jpg"

function Security() {
  return (
    <div>
      <div className="mobile-heading"></div>
      <div className="">
        <div style={{ marginTop: "105px" }}></div>
        <div className="feature1" id="6">
          Security and Access
        </div>
        {/* Sixth Box */}
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img src={feature6} alt="featureImage" />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <div className="spacer"></div>
            {/* <div className="spacer"></div> */}
            <p className="feature-left-content">
              Deployed on AWS cloud, Korero has number of features to ensure
              safety of your data. Built for omni-channel access 24/7, Korero is
              laptop and tablet friendly, mobile app to be released shortly.
            </p>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}></div>
        <div className="feature-main-box ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading"> Security</div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={security2} alt="security2" height="150vh" />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero is deployed on AWS (Paris location) that ensures high level
              of security, we have included password encryption and tokenized
              access to ensure that your data stays protected 24/7.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={security2} alt="security2" height="250vh" />
            </div>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="wrapping">
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Dashboard_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Contact_Mobile_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Event_Add_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">Access</div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={mobile} alt="mobile" height="150vh" />
              </div>
            </div>
            <p className="feature-left-box-content">
              Currently Korero desktop, laptop and tablet friendly, accessible
              24/7 online. Korero’s mobile app is available on Google Play
              store, we aim to achieve omni channel presence by Jan 2021 with
              app also rolled out on Apple store.
            </p>
          </div>
        </div>
        {/* /* left image box */}

        <div style={{ marginTop: "150px" }}></div>
      </div>
      <div className="feature-container-3">
        <Signup />
      </div>
    </div>
  )
}

export default Security
